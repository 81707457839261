import React from "react";
import Ourservish from "../Menhome/Ourservices";
import Book from "../Menhome/Booking";
import Testimo from "../Menhome/Testimonialslider";
import Faq from "../Menhome/Faqsection";
import OurDr from "../Menhome/Ourdr";
import Appointment from "../Menhome/Appointment";
import Blog from "../Menhome/Blog";
import Homevideo from "../Homethreevideo/Homevideo";

function Main() {
  return (
    <>
      <Homevideo />
      <Ourservish />
      <Book />
      <Testimo />
      <Faq />
      <OurDr />
      <Appointment />
      <Blog />
    </>
  );
}

export default Main;
