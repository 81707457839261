import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routing from './routes'; // Asegúrate de que './routes' es el archivo correcto que define tus rutas

function App() {
    return (
        <Router>
            <Routing />
        </Router>
    );
}

export default App;

