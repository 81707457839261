import React from 'react';
import Logo from '../../assets/img/logo/f_logo.png';
import { Link } from 'react-router-dom';
import FLAG_IMG from './../../assets/img/flag_ue.png';
function Main() {
    return (
        <>
            <footer className="footer-bg footer-p pt-90" style={{ backgroundColor: '#00173c' }}>
                <div className="footer-top pb-70">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-4 col-lg-4 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        {/* <h2>
                                            
                                            <img src={Logo} alt="logo" />
                                        </h2> */}
                                        <h1 style={{ color: 'white' }}>Dentesthetics</h1>
                                    </div>
                                    <div className="footer-link">
                                        <p>
                                            Mergi la stomatolog și menține-ți sănătatea în cea mai
                                            bună formă.
                                        </p>
                                        <div className="f-contact">
                                            <ul>
                                                <li>
                                                    <i className="icon fal fa-clock" />
                                                    <span>
                                                        Luni - Vineri:
                                                        <br />
                                                        9:00 - 23:00
                                                    </span>
                                                </li>
                                                <li>
                                                    <i className="icon fal fa-clock" />
                                                    <span>
                                                        Sambata:
                                                        <br />
                                                        10:00 - 16:00
                                                    </span>
                                                </li>
                                                <li>
                                                    <i className="icon fal fa-clock" />
                                                    <span>
                                                        Duminica:
                                                        <br />
                                                        Inchis
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-3 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Informează-te</h2>
                                    </div>
                                    <div className="footer-link">
                                        <ul>
                                            <li>
                                                <Link to="/"> Acasa</Link>
                                            </li>
                                            <li>
                                                <Link to="/dental-education">Educatie dentara</Link>
                                            </li>
                                            <li>
                                                <Link to="/medical-procedures">
                                                    Proceduri medicale
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/aesthetics-smile">
                                                    Zambetul ESTHETICS
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/myth-or-truth">Mit | Adevar</Link>
                                            </li>
                                            <li>
                                                <Link to="/contact">Contact</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="f-widget-title">
                                    <h2>Parteneri</h2>
                                </div>
                                <div className="f-contact">
                                    <ul>
                                        {/* <li>
                                                <h5 style={{ color: 'white' }}>Parteneri</h5>
                                            </li> */}
                                        <li>
                                            <i className="icon fal fa-map-marker-check" />
                                            <span>
                                                Cabinet RX-Dentis Targu Frumos
                                                <br /> Policlinica, Strada Petru Rares nr.58
                                            </span>
                                        </li>
                                        <li>
                                            <i className="icon fal fa-map-marker-check" />
                                            <span>
                                                Cabinet ESTHETICS Suceava
                                                <br /> Strada Ana Ipatescu nr. 2, bl. E1
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="col-xl-2 col-lg-2 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                <h2>Our Services</h2>
                                </div>
                                <div className="footer-link">
                                <ul>
                                    <li>
                                    <Link to="/singleservice"> Root Canal</Link>
                                    </li>
                                    <li>
                                    <Link to="/singleservice">Alignment Teeth</Link>
                                    </li>
                                    <li>
                                    <Link to="/singleservice"> Cosmetic Teeth</Link>
                                    </li>
                                    <li>
                                    <Link to="/singleservice">Oral Hygiene</Link>
                                    </li>
                                    <li>
                                    <Link to="/singleservice">Live Advisory</Link>
                                    </li>
                                    <li>
                                    <Link to="/singleservice">Cavity Inspection</Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div> */}
                            <div className="col-xl-4 col-lg-4 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Contact</h2>
                                    </div>
                                    <div className="f-contact">
                                        <ul>
                                            {/* <li>
                                                <h5 style={{ color: 'white' }}>Locatii</h5>
                                            </li> */}
                                            <li>
                                                <i className="icon fal fa-map-marker-check" />
                                                <span
                                                    style={{
                                                        justifyContent: 'center',
                                                        height: '100%',
                                                        // background: 'red',
                                                        top: '14px',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    Iasi, strada Pantelimon Halipa nr 10
                                                </span>
                                            </li>
                                            {/* <li>
                                                <i className="icon fal fa-map-marker-check" />
                                                <span>
                                                    Cabinet stomatologic Non Stop Esthetics
                                                    <br />
                                                    Bulevardul Nicolae Iorga, nr. 7, bl. K1, parter
                                                </span>
                                            </li> */}

                                            <li>
                                                <i className="icon fal fa-phone" />
                                                <span
                                                    style={{
                                                        justifyContent: 'center',
                                                        height: '100%',
                                                        // background: 'red',
                                                        top: '14px',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    0774 000 112
                                                </span>
                                            </li>
                                            <li>
                                                <i className="icon fal fa-envelope" />
                                                <span
                                                    style={{
                                                        justifyContent: 'center',
                                                        height: '100%',
                                                        // background: 'red',
                                                        top: '14px',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <Link to="mailto:olazdent@gmail.com">
                                                        olazdent@gmail.com
                                                    </Link>
                                                    {/* <br />
                                                    <Link to="mailto:office@clinicaesthetics.ro">
                                                        office@clinicaesthetics.ro
                                                    </Link> */}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="containerUE">
                                        <img src={FLAG_IMG} />
                                        <p>
                                            Aceasta pagina a fost creata prin programul Start-UP
                                            Nation pentru Olazdent SRL
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copyright-wrap">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="copy-text">
                                    Copyright © 2023 decare. All rights reserved.
                                </div>
                            </div>
                            <div className="col-lg-4"></div>
                            <div className="col-lg-4">
                                <div className="footer-social">
                                    <a href="https://www.facebook.com/">
                                        <i className="fab fa-facebook-f" />
                                    </a>
                                    <a href="https://www.instagram.com/">
                                        <i className="fab fa-instagram" />
                                    </a>
                                    <a href="https://twitter.com/">
                                        <i className="fab fa-twitter" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Main;
