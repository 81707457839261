import React from 'react';
//import Video from '../../assets/img/slider/slider-vedio.mp4'
import { Link } from 'react-router-dom';

const Homevideo = () => {
    return (
        <div>
            <section id="home" className="slider-area fix p-relative">
                <div className="slider-active" style={{ background: '#00173c' }}>
                    <div
                        className="single-slider slider-bg d-flex slider-four slider-bg-three align-items-center"
                        style={{
                            backgroundImage: 'url(img/slider/slider_img01.png)',
                            backgroundSize: 'cover'
                        }}
                    >
                        <div className="container">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-10 col-md-10 text-center">
                                    <div className="slider-content s-slider-content slider-text-2 mt-20">
                                        {/* <div className="contentHome1"> */}
                                        <h2 data-animation="fadeInUp" data-delay=".4s">
                                            Ghidul Zâmbetului Perfect
                                        </h2>
                                        <p data-animation="fadeInUp" data-delay=".4s">
                                            "Descoperă secretele unui zâmbet sănătos și strălucitor
                                            cu DentEsthetics! Explorează sfaturi de nutriție,
                                            tehnici de îngrijire dentară și educație pentru un
                                            zâmbet perfect. Vizitează-ne acum pentru a începe
                                            călătoria către cea mai bună versiune a zâmbetului tău!"
                                        </p>
                                        {/* </div> */}
                                        {/* <div className="contentHome2">
                                            <h2 data-animation="fadeInUp" data-delay=".4s">
                                                Sursa ta de încredere pentru cel mai frumos zambet
                                            </h2>
                                            <p data-animation="fadeInUp" data-delay=".4s">
                                                Idei și sugestii utile pentru o sănătate orală
                                                corecta si durabilă Recomandări pentru o alimentație
                                                echilibrată care susține sănătatea dinților și
                                                gingiilor
                                            </p>
                                        </div> */}

                                        <div className="slider-btn mt-20">
                                            <Link
                                                to="/contact"
                                                className="btn ss-btn"
                                                data-animation="fadeInLeft"
                                                data-delay=".4s"
                                            >
                                                Contact
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <video id="my-video" className="video2" muted loop autoPlay>
                            {/* <source src={Video} type="video/mp4" /> */}
                            <source src="img/slider/slider-vedio.ogv" type="video/ogg" />
                            <source src="img/slider/slider-vedio.webm" type="video/webm" />
                        </video>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Homevideo;
