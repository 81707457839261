import React from 'react';
import Make from '../../components/MenContact-Us/Make';
import Bredcm from '../../components/Menhome/breadcrumb';
import Map from '../../components/MenContact-Us/Map';
import { Link } from 'react-router-dom';

const Contact = () => {
    const ItemContact = ({ title, titleBold, location, tel, time }) => {
        return (
            <div
                style={{
                    backgroundColor: '#00173c',
                    padding: '50px',
                    maxWidth: '100%',
                    borderRadius: '20px'
                }}
            >
                <div className="f-widget-title">
                    <h2>
                        {title} <span style={{ color: '#3660d9' }}> {titleBold}</span>
                    </h2>
                </div>
                <div className="f-contact">
                    <ul>
                        {/* <li>
                                                <h5 style={{ color: 'white' }}>Locatii</h5>
                                            </li> */}
                        <li>
                            <i className="icon fal fa-map-marker-check" />
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    //                justifyContent: 'center',
                                    height: '55px'
                                }}
                            >
                                {location}
                            </span>
                        </li>

                        <li>
                            <i className="icon fal fa-phone" />
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    //                justifyContent: 'center',
                                    height: '55px'
                                }}
                            >
                                {tel}
                            </span>
                        </li>
                        <li>
                            <i className="icon fal fa-envelope" />
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    //                justifyContent: 'center',
                                    height: '55px'
                                }}
                            >
                                <Link to="mailto:office@clinicaesthetics.ro">
                                    office@clinicaesthetics.ro
                                </Link>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };
    return (
        <>
            <Bredcm subtitle="Contact Us" title="Home" subtitledown="about-Us" />
            <Make />

            <div className="container my-5">
                <div className="row justify-content-between g-5">
                    <div className="col-xl-6 col-lg-6 col-sm-12">
                        <ItemContact
                            title={'Clinica Stomatologică'}
                            titleBold={'ESTHETICS'}
                            location={'Iași, Strada Pantelimon Halipa Nr. 10'}
                            tel={'0733 088 545'}
                            time={'Luni-Vineri 09:00 - 23:00 | Sâmbătă 10:00 - 16:00'}
                        />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-sm-12">
                        <ItemContact
                            title={'Cabinet URGENȚE NON-STOP '}
                            titleBold={'ESTHETICS'}
                            location={'Iași, Bvd. Nicolae Iorga Nr. 7H Bl. K1'}
                            tel={'0771 112 112'}
                            time={'NON-STOP'}
                        />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-sm-12">
                        <ItemContact
                            title={'Cabinet Stomatologic'}
                            titleBold={'ESTHETICS Suceava'}
                            location={'Suceava, Strada Ana Ipătescu, nr. 2, bl E1, sc. D'}
                            tel={'0770 638 263'}
                            time={'Luni-Vineri - 09.00 - 17.00'}
                        />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-sm-12">
                        <ItemContact
                            title={'Cabinet Stomatologic '}
                            titleBold={' RX-Dentis Târgu Frumos'}
                            location={
                                'Târgu Frumos, Strada Petru Rareș, nr. 56, Policlinică, etaj 1'
                            }
                            tel={'0771 669 507'}
                            time={'Luni-Vineri 09:00 - 17:00'}
                        />
                    </div>
                </div>
            </div>

            <Map />
        </>
    );
};

export default Contact;
