import React from 'react';
import Bredcm from '../../components/Menhome/breadcrumb';
//import GallerySec from '../../components/PagesMen/Gallery/Main';
import CustomCompareImages from '../../components/CustomComponents/CustomCompareImages/CustomCompareImages';
import Cariologie1A from './../../assets/img/compare/cariologie/01/after-1.jpg';
import Cariologie1B from './../../assets/img/compare/cariologie/01/before-1.jpg';
import Cariologie2A from './../../assets/img/compare/cariologie/02/after2.jpg';
import Cariologie2B from './../../assets/img/compare/cariologie/02/before2.jpg';
import Fatete3A from './../../assets/img/compare/fatete/03/after03.jpg';
import Fatete3B from './../../assets/img/compare/fatete/03/before03.jpg';
import Fatete4A from './../../assets/img/compare/fatete/04/after04.jpg';
import Fatete4B from './../../assets/img/compare/fatete/04/before04.jpg';
import Fatete4A1 from './../../assets/img/compare/fatete/04/after04.1.jpg';
import Fatete4B1 from './../../assets/img/compare/fatete/04/before04.1.jpg';
import Fatete5A from './../../assets/img/compare/fatete/05/after05.jpg';
import Fatete5B from './../../assets/img/compare/fatete/05/before05.jpg';
import Fatete5A1 from './../../assets/img/compare/fatete/05/after05.1.jpg';
import Fatete5B1 from './../../assets/img/compare/fatete/05/before05.1.jpg';
import Fatete6A from './../../assets/img/compare/fatete/06/after06.jpg';
import Fatete6B from './../../assets/img/compare/fatete/06/before06.jpg';
import Fatete7A from './../../assets/img/compare/fatete/07/after07.jpg';
import Fatete7B from './../../assets/img/compare/fatete/07/before07.jpg';
import Fatete8A from './../../assets/img/compare/fatete/08/after08.jpg';
import Fatete8B from './../../assets/img/compare/fatete/08/before08.jpg';
import Implanturi9A from './../../assets/img/compare/implanturi/09/after09.jpg';
import Implanturi9B from './../../assets/img/compare/implanturi/09/before09.jpg';
import Implanturi10A from './../../assets/img/compare/implanturi/10/after10.jpg';
import Implanturi10B from './../../assets/img/compare/implanturi/10/before10.jpg';
import Implanturi11A from './../../assets/img/compare/implanturi/11/after11.jpg';
import Implanturi11B from './../../assets/img/compare/implanturi/11/before11.jpg';
import Protetica12A from './../../assets/img/compare/protetica/12/after12.jpg';
import Protetica12B from './../../assets/img/compare/protetica/12/before12.jpg';
import Protetica13A from './../../assets/img/compare/protetica/13/after13.jpg';
import Protetica13B from './../../assets/img/compare/protetica/13/before13.jpg';
const AestheticsSmile = () => {
    const TitleAux = ({ text }) => {
        return (
            <h1
                style={{
                    width: '100%',
                    textAlign: 'center',
                    paddingTop: '50px',
                    color: '#3660d9',
                    textTransform: 'uppercase'
                }}
            >
                {text}
            </h1>
        );
    };
    return (
        <>
            <Bredcm subtitle="Zambetul ESTHETICS" title="Acasa" subtitledown="Zambetul ESTHETICS" />
            <TitleAux text={'Cariologie'} />
            <CustomCompareImages
                title={'Obturatii (plombe) din compozit pe dintii frontali'}
                images={[Cariologie1B, Cariologie1A]}
            />
            <CustomCompareImages
                title={'Tratamentul leziunilor carioase de la nivelul coletului dentar'}
                images={[Cariologie2B, Cariologie2A]}
            />
            <TitleAux text={'Fatete'} />
            <CustomCompareImages
                title={'Fatete ceramica presata la nivelul incisivilor si caninilor'}
                images={[Fatete3B, Fatete3A]}
            />
            <CustomCompareImages
                title={'Fatete ceramica presata la nivelul incisivilor si caninilor '}
                images={[Fatete4B, Fatete4A, Fatete4B1, Fatete4A1]}
            />
            <CustomCompareImages
                title={'Fatete compozit la nivelul incisivilor centrali si laterali'}
                images={[Fatete5B, Fatete5A, Fatete5B1, Fatete5A1]}
            />
            <CustomCompareImages
                title={'Fatete ceramica presata la nivelul incisivilor si caninilor'}
                images={[Fatete6B, Fatete6A]}
            />
            <CustomCompareImages
                title={'Fateta ceramica presata la nivelul incisivului lateral drept'}
                images={[Fatete7B, Fatete7A]}
            />
            <CustomCompareImages
                title={'Fatete ceramica presata la nivelul incisivilor si caninilor'}
                images={[Fatete8B, Fatete8A]}
            />

            <TitleAux text={'Implanturi'} />
            <CustomCompareImages
                title={'Proteza telescopata cu structura de titan pe suport implantar'}
                images={[Implanturi9B, Implanturi9A]}
            />
            <CustomCompareImages
                title={'Lucrari protetice zirconiu integral insurubate pe implanturi'}
                images={[Implanturi10B, Implanturi10A]}
            />
            <CustomCompareImages
                title={
                    'Proteza mobila CANDULOR pe maxilar Lucrare protetica zirconiu pe suport de titan pe implanturi la mandibula'
                }
                images={[Implanturi11B, Implanturi11A]}
            />

            <TitleAux text={'Protetica'} />
            <CustomCompareImages
                title={
                    'Lucrare protetica ceramica pe suport de zirconiu la nivelul incisivilor centrali si laterali'
                }
                images={[Protetica12B, Protetica12A]}
            />
            <CustomCompareImages
                title={'Lucrari protetice ceramica pe suport de zirconiu maxilar si mandibula'}
                images={[Protetica13B, Protetica13A]}
            />
            {/* <GallerySec /> */}
        </>
    );
};

export default AestheticsSmile;
