import React from 'react';
import Bredcm from '../../components/Menhome/breadcrumb';
import Ourservish from '../../components/Menhome/Ourservices';
import CustomSection from '../../components/CustomSection/CustomSection';
import DentalEducation1 from './../../assets/img/dental-education/1.jpg';
import DentalEducation2 from './../../assets/img/dental-education/2.jpg';
import DentalEducation3 from './../../assets/img/dental-education/3.jpg';
import DentalEducation4 from './../../assets/img/dental-education/4.jpg';
import DentalEducation5 from './../../assets/img/dental-education/5.jpg';
import DentalEducation6 from './../../assets/img/dental-education/6.jpg';
import DentalEducation7 from './../../assets/img/dental-education/7.jpg';
import DentalEducation8 from './../../assets/img/dental-education/8.jpg';
import DentalEducation9 from './../../assets/img/dental-education/9.jpg';

const DentalEducation = () => {
    return (
        <>
            <Bredcm subtitle="Educatie dentara" title="Acasa" subtitledown="Educatie dentara" />
            <Ourservish />
            <CustomSection
                image={
                    <>
                        <img src={DentalEducation1} alt="img" />
                        {/* <div className="about-text second-about">
                            <span>14</span>
                            <p>Ani de experienta</p>
                        </div> */}
                    </>
                }
                content={
                    <>
                        <strong>Periajul regulat al dinților:</strong>

                        <ul>
                            <li>
                                Periază-ți dinții de cel puțin două ori pe zi, dimineața și seara,
                                timp de cel puțin 2 minute, cu presiune moderată și cu mișcări
                                verticale, dinspre gingie spre marginea libera a dintelui.
                            </li>
                            <li>
                                Folosește o periuță de dinți cu peri moi și înlocuiește-o la fiecare
                                3-4 luni sau mai devreme dacă perii sunt uzați.
                            </li>
                            <li>
                                Asigură-te că perii periuței ating toate suprafețele dinților și
                                gingiilor.
                            </li>
                        </ul>
                        <strong>Utilizarea corectă a aței dentare:</strong>

                        <ul>
                            <li>
                                Folosește ața dentară zilnic pentru a curăța spațiile dintre dinți
                                și sub linia gingiilor.
                            </li>
                        </ul>
                        <strong>Clătirea cu apă de gură:</strong>

                        <ul>
                            <li>
                                Clătește cu apă de gură fără alcool pentru a elimina bacteriile și a
                                împroaspăta respirația după fiecare masă.
                            </li>
                            <li>
                                Evită clătirea cu apă de gură imediat după periaj, deoarece acest
                                lucru poate spăla fluorul din pastă de dinți.
                            </li>
                        </ul>
                        <strong>Alimentație echilibrată:</strong>

                        <ul>
                            <li>
                                Limitează consumul de alimente și băuturi bogate în zahăr, care pot
                                contribui la formarea cariilor dentare.
                            </li>
                            <li>
                                Alege alimente bogate în nutrienți esențiali, cum ar fi fructe,
                                legume, nuci si semințe, care pot ajuta la menținerea sănătății
                                orale.
                            </li>
                        </ul>
                        <strong>Evitarea obiceiurilor nocive:</strong>

                        <ul>
                            <li>
                                Evită fumatul și mestecatul tutunului, care pot duce la probleme
                                dentare grave, precum cariile și boala parodontală.
                            </li>
                            <li>
                                Limitează consumul de băuturi alcoolice, care pot usca gura și pot
                                crește riscul de carii dentare.
                            </li>
                        </ul>
                        <strong>Vizite regulate la dentist:</strong>

                        <ul>
                            <li>
                                Programați-vă vizite regulate la dentist pentru un control de rutină
                                și o curățare profesională a dinților și gingiilor.
                            </li>
                            <li>
                                Consultați dentistul în caz de durere, sângerare gingivală sau alte
                                probleme dentare.
                            </li>
                        </ul>
                    </>
                }
                subTitle={'Igiena orală '}
                title={'Cum realizăm igiena orală în mod corect'}
            />

            <CustomSection
                image={
                    <>
                        <img src={DentalEducation2} alt="img" />
                        {/* <div className="about-text second-about">
                            <span>14</span>
                            <p>Ani de experienta</p>
                        </div> */}
                    </>
                }
                content={
                    <>
                        <p>
                            O strălucire a zâmbetului este adesea dorită, iar albirea dinților poate
                            fi soluția la îndemână pentru a obține acest efect. Când este necesară
                            albirea dinților:
                        </p>
                        <p>
                            <strong> Dinți pătați sau decolorați: </strong> Consumul de cafea, ceai,
                            vin roșu sau fumatul pot contribui la decolorarea dinților, iar albirea
                            poate fi o modalitate eficientă de a inversa acest proces.
                        </p>
                        <p>
                            <strong>Varsta: </strong> Odată cu înaintarea în vârstă, smalțul
                            dinților devine mai subțire, expunând stratul subiacent de dentină care
                            este mai gălbui
                        </p>
                        <p>
                            <strong> Tratamente dentare anterioare: </strong>
                            Anumite materiale care se utilizau în trecut în special în tratamentele
                            radiculare aveau un efect de modificare a culorii dintelui, aspect ce se
                            poate îmbunătăți cu ajutorul substanțelor de albire.
                        </p>
                        <p>Beneficii:</p>
                        <p>
                            <strong>Aspect îmbunătățit al zâmbetului: </strong>
                            Albirea poate oferi un zâmbet mai strălucitor și mai atrăgător, crescând
                            încrederea în sine și îmbunătățind aspectul general al feței.
                        </p>
                        <p>
                            <strong> Rezultate rapide: </strong>
                            Albirea dinților este adesea o procedură rapidă, cu rezultate vizibile
                            într-un timp relativ scurt.
                        </p>
                        <p>
                            <strong>Procedură non-invazivă: </strong>
                            În general, albirea dinților este o opțiune non-invazivă și nedureroasă
                            pentru a îmbunătăți aspectul zâmbetului. Riscuri:
                        </p>
                        <p>
                            <strong>Sensibilitate dentară: </strong>
                            Unul dintre cele mai comune efecte secundare ale albirei dinților este
                            sensibilitatea temporară a dinților la temperaturi reci sau calde.
                        </p>
                        <p>
                            <strong>Pierderea efectului de albire: </strong>
                            Din cauza consumului de alimente și băuturi colorate, dinții se vor
                            îngalbeni înapoi la nuanța inițială, fiind astfel necesară repetarea
                            procedurii după un interval de aproximativ 6-8 luni.
                        </p>
                        <p>
                            <strong> Rezultate nesatisfăcătoare: </strong>
                            Albirea dinților poate oferi rezultate variabile în funcție de substanța
                            utilizată, de tipul de procedură (la domiciliu sau în cabinetul
                            stomatologic utilizată), dar și de nuanța inițiala a fiecărui pacient,
                            astfel încât există posibilitatea ca rezultatul să nu fie întotdeauna
                            cel așteptat.
                        </p>
                        <p>Cât de des este recomandată albirea dinților:</p>
                        <p>
                            Frecvența albirii dinților poate varia în funcție de preferințele
                            individuale și de starea de sănătate a dinților. Cu toate acestea, se
                            recomandă să nu se efectueze mai des de două ori pe an, pentru a evita
                            deteriorarea smalțului sau sensibilitatea excesivă a dinților. Este
                            esențial să consultăm un dentist înainte de a începe orice procedură de
                            albire, pentru a înțelege riscurile și beneficiile specifice și pentru a
                            ne asigura că alegem cea mai potrivită opțiune pentru nevoile noastre
                            dentare individuale.
                        </p>
                    </>
                }
                subTitle={'Albirea dinților '}
                title={'Când și cum să alegi această procedură'}
                v1
            />
            <CustomSection
                image={
                    <>
                        <img src={DentalEducation3} alt="img" />
                        {/* <div className="about-text second-about">
                            <span>14</span>
                            <p>Ani de experienta</p>
                        </div> */}
                    </>
                }
                content={
                    <>
                        <p>
                            Alegerea celei mai potrivite paste de dinți poate părea copleșitoare,
                            dat fiind numărul mare de opțiuni disponibile pe piață. Iată câteva
                            aspecte de luat în considerare atunci când îți alegi pasta de dinți:
                        </p>
                        <p>
                            <strong>Prezența fluorului:</strong>
                            Fluorul este esențial pentru prevenirea cariilor dentare și
                            remineralizarea smalțului, dar trebuie să te asiguri că pasta de dinți
                            pe care o alegi conține fluor într-o concentrație adecvată vârstei și
                            afecțiunilor dentare existente.
                        </p>
                        <p>
                            <strong>Sensibilitate dentară: </strong>Dacă ai sensibilitate la nivelul
                            dinților, alege o pastă de dinți special formulată pentru acest lucru,
                            care conține ingrediente calmante, cum ar fi nitrați de potasiu sau
                            clorură de stronțiu.
                        </p>
                        <p>
                            <strong>Prevenirea tartrului:</strong>Pentru a preveni acumularea de
                            tartru, alege o pastă de dinți care conține ingrediente active, cum ar
                            fi pirofosfat de sodiu sau hexametafosfat de sodiu.
                        </p>
                        <p>
                            <strong>Preferințele personale:</strong>Alege o pastă de dinți cu un
                            gust și o textură care îți plac și te fac să te simți confortabil în
                            timpul periajului dentar.
                        </p>
                        <p>
                            <strong>Acreditări dentare: </strong>Verifică dacă pasta de dinți este
                            aprobată de organizațiile de sănătate dentară.
                        </p>
                        <p>
                            <strong>Opiniile utilizatorilor:</strong>Consultă recenziile și
                            evaluările altor utilizatori pentru a obține o perspectivă despre
                            eficacitatea și plăcerea utilizării acelei paste de dinți.
                        </p>
                        <p>
                            <strong>Recomandările profesionale: </strong> Întreabă-ți dentistul
                            despre recomandările lor privind pasta de dinți, luând în considerare
                            nevoile și problemele tale dentare individuale.
                        </p>
                    </>
                }
                subTitle={'Pasta de dinți'}
                title={'Cum alegem pasta de dinți'}
            />
            <CustomSection
                image={
                    <>
                        <img src={DentalEducation4} alt="img" />
                        {/* <div className="about-text second-about">
                            <span>14</span>
                            <p>Ani de experienta</p>
                        </div> */}
                    </>
                }
                content={
                    <>
                        <p>
                            Schimbările hormonale din timpul sarcinii pot afecta sănătatea orală,
                            crescând riscul de apariție a cariilor, inflamație gingivală, afectare
                            parodontală, dar și alte probleme dentare. Câteva sfaturi simple pentru
                            a menține sănătatea dinților și a gingiilor:
                        </p>
                        <p>
                            <strong> Periajul și folosirea aței dentare și a apei de gură: </strong>
                            Nu uita să periezi dinții de cel puțin două ori pe zi cu o pastă de
                            dinți cu fluor. De asemenea, folosește ața dentară și apa de gură în mod
                            regulat pentru a îndepărta placa și resturile alimentare dintre dinți.
                        </p>
                        <p>
                            <strong> Vizitele regulate la dentist: </strong>
                            Programarea unor vizite regulate la dentist este crucială în timpul
                            sarcinii. Dentistul poate identifica și trata problemele dentare în
                            stadii incipiente și poate oferi sfaturi pentru îngrijirea orală
                            corectă.
                        </p>
                        <p>
                            <strong>Monitorizarea gingiilor: </strong>Fii atentă la orice semne de
                            inflamație sau sângerare a gingiilor. Dacă observi orice schimbare sau
                            disconfort, consultă imediat un dentist.
                        </p>
                        <p>
                            <strong>Alimentația sănătoasă: </strong>O alimentație echilibrată și
                            sănătoasă, bogată în nutrienți esențiali, poate contribui la menținerea
                            sănătății orale în timpul sarcinii. Limitează consumul de dulciuri și
                            alimente bogate în zahăr care pot crește riscul de carii dentare.
                        </p>
                        <p>
                            <strong>Evitarea stresului: </strong>Stresul poate agrava problemele
                            gingivale și poate afecta sănătatea orală în general. Încearcă să
                            găsești modalități eficiente de gestionare a stresului, cum ar fi
                            meditația sau exercițiile de respirație.
                        </p>
                    </>
                }
                subTitle={'Sarcina si sănătatea orală'}
                title={'Îngrijirea stomatologică în timpul sarcinii'}
                v1
            />
            <CustomSection
                image={
                    <>
                        <img src={DentalEducation5} alt="img" />
                        {/* <div className="about-text second-about">
                            <span>14</span>
                            <p>Ani de experienta</p>
                        </div> */}
                    </>
                }
                content={
                    <>
                        <p>
                            <strong>Comunicare deschisă cu dentistul: </strong>
                            Vorbește deschis cu dentistul despre temerile și anxietățile tale
                            înainte de începerea tratamentului. Un dentist înțelegător va putea
                            să-ți ofere informații suplimentare despre procedurile dentare și să
                            răspundă la întrebări pentru a-ți calma anxietatea.
                        </p>
                        <p>
                            <strong>Alegerea unui dentist de încredere: </strong>
                            Găsește un dentist cu care te simți confortabil și în care ai încredere.
                            Un medic stomatolog empatic și înțelegător poate face o diferență
                            semnificativă în confortul tău în timpul vizitelor la cabinetul
                            stomatologic.
                        </p>
                        <p>
                            <strong>Programarea vizitei în momente favorabile: </strong>
                            Evită programarea la orele târzii ale serii sau înainte de evenimente
                            stresante.
                        </p>
                        <p>
                            <strong>Tehnici de relaxare: </strong>
                            Încercă diverse tehnici de relaxare, cum ar fi respirația profundă,
                            meditația sau ascultarea muzicii relaxante, înainte și în timpul vizitei
                            la dentist.
                        </p>
                        <p>
                            <strong> Anunțarea personalului despre temerile tale: </strong> Spune
                            personalului despre temerile tale. Ei pot fi capabili să ofere un suport
                            suplimentar și să-ți ofere confortul necesar.
                        </p>
                        <p>
                            <strong> Distracția în timpul tratamentului: </strong>Adesea, ascultarea
                            muzicii sau vizionarea unui film pe un ecran poate distrage atenția de
                            la procedurile stomatologice și poate reduce anxietatea.
                        </p>
                        <p>
                            <strong>Etapizarea în tratarea problemelor: </strong>Încearcă să discuți
                            cu dentistul despre o abordare graduală a tratamentului. Poate fi util
                            să începi cu proceduri mai mici și să te obișnuiești treptat cu
                            cabinetul stomatologic.
                        </p>
                        <p>
                            <strong>Terapia cognitiv-comportamentală: </strong>În unele cazuri,
                            terapia cognitiv-comportamentală sau consilierea psihologică poate fi
                            utilă pentru a gestiona anxietatea legată de vizitele la dentist și
                            pentru a dezvolta strategii față de abordarea temerilor tale.
                        </p>
                    </>
                }
                subTitle={'Teama de dentist '}
                title={'Cum depașim teama de dentist'}
            />
            <CustomSection
                image={
                    <>
                        <img src={DentalEducation6} alt="img" />
                        {/* <div className="about-text second-about">
                            <span>14</span>
                            <p>Ani de experienta</p>
                        </div> */}
                    </>
                }
                content={
                    <>
                        <p>
                            <strong> Eroziunea smalțului:</strong>Consumul excesiv de alimente și
                            băuturi acide sau boala de reflux gastro-esofagian pot duce la eroziunea
                            smalțului dentar, expunând stratul de dentină la stimulii termici și
                            chimici. Dentina înglobează în grosimea ei niște filete nervoase foarte
                            subțiri, iar expunerea la factori iritanți va provoca senzația de
                            durere.
                        </p>
                        <p>
                            <strong>Recesiunea gingiilor: </strong>Retragerea gingiilor poate expune
                            rădăcinile sensibile ale dinților, provocând sensibilitate la
                            temperaturi și stimuli.
                        </p>
                        <p>
                            <strong> Caria dentară: </strong>
                            Caria dentară care afectează smalțul sau dentina poate provoca
                            sensibilitate la dulce, acru, fierbinte sau rece.
                        </p>
                        <p>
                            <strong> Plomba dentară deteriorată: </strong>O plombă dentară veche sau
                            deteriorată poate permite intrarea bacteriilor și a substanțelor
                            iritante în interiorul dinților, provocând sensibilitate.
                        </p>
                        <p>
                            <strong> Periajul dentar agresiv: </strong>
                            Periajul dentar prea agresiv sau utilizarea unei periuțe de dinți cu
                            peri duri poate duce la uzura smalțului și la expunerea dentinei
                            sensibile.
                        </p>
                        <p>
                            <strong>Bruxismul: </strong>
                            Presiunea excesivă exercitată asupra dinților prin clenching sau bruxism
                            poate agrava sensibilitatea dentară. Utilizarea unui dispozitiv de
                            protecție pe timpul noapții poate fi utilă.
                        </p>
                        <strong>Tratamente pentru sensibilitatea dentară:</strong>
                        <p>
                            <strong> Pastă de dinți desensibilizantă:</strong>Utilizarea unei paste
                            de dinți speciale pentru sensibilitate poate ajuta la sigilarea
                            tubulilor dentinari expuși și la reducerea disconfortului.
                        </p>
                        <p>
                            <strong>Fluorizare: </strong>
                            Tratamentele cu fluor au rolul de a remineraliza smalțul dentar și
                            contribuie la reducerea sensibilității.
                        </p>
                        <p>
                            <strong>Obturații dentare: </strong>În situația în care dintele prezintă
                            o carie ce provoacă sensibilitate, tratarea cariei și aplicarea unei
                            plombe dentare pot ajuta la eliminarea disconfortului.
                        </p>
                        <p>
                            <strong>Tratamente de canal radicular: </strong>
                            În cazurile severe de sensibilitate dentară, când nervul dentar este
                            afectat, un tratament de canal radicular poate fi necesar pentru a
                            îndepărta senzația de durere.
                        </p>
                    </>
                }
                subTitle={'Sensibilitatea dentară'}
                title={'Cauzele sensibilității dentare'}
                v1
            />
            <CustomSection
                image={
                    <>
                        <img src={DentalEducation7} alt="img" />
                        {/* <div className="about-text second-about">
                            <span>14</span>
                            <p>Ani de experienta</p>
                        </div> */}
                    </>
                }
                content={
                    <>
                        <p>
                            <strong> Reacții alergice:</strong>
                            Unele materiale dentare sau anestezice pot provoca reacții alergice la
                            anumite persoane.
                        </p>
                        <p>
                            <strong>Infecții postoperatorii: </strong>
                            Orice procedură care implică intervenții chirurgicale poate prezenta
                            riscul de infecții postoperatorii.
                        </p>
                        <p>
                            <strong>Durere și disconfort: </strong>
                            Unele proceduri stomatologice pot fi asociate cu durere și disconfort
                            temporar în timpul sau după tratament.
                        </p>
                        <p>
                            <strong>Complicații la nivelul gingiilor sau mucoasei orale: </strong>
                            Anumite proceduri, cum ar fi detartrajul profund, aplicarea unui sistem
                            de izolare tip digă sau protezele mobile pot duce la complicații la
                            nivelul gingiilor sau mucoasei orale.
                        </p>
                        <p>
                            <strong>Sensibilitate dentară: </strong>
                            Albirea dinților sau tratamentul unei carii profunde pot duce la
                            sensibilitate dentară temporară.
                        </p>
                        <p>
                            <strong>Probleme legate de restaurări: </strong>
                            Restaurările dentare, cum ar fi plombele sau coroanele, pot necesita
                            ajustări ulterioare sau pot prezenta unele complicații, de exemplu,
                            fracturarea sau decimentarea restaurarii.
                        </p>
                        <p>
                            <strong>Probleme ale articulației temporo-mandibulare (ATM): </strong>
                            În cazul unor tratamente prin care se modifică poziția dinților sau
                            ocluzia, însemnând contactele dintre dinți, este posibil să apară
                            disfuncții ale articulației temporo-mandibulare.
                        </p>
                    </>
                }
                subTitle={'Riscurile procedurilor stomatologice '}
                title={'Care sunt riscurile unui tratamen dentar'}
            />
            <CustomSection
                image={
                    <>
                        <img src={DentalEducation8} alt="img" />
                        {/* <div className="about-text second-about">
                            <span>14</span>
                            <p>Ani de experienta</p>
                        </div> */}
                    </>
                }
                content={
                    <>
                        <p>
                            <strong>Învățarea prin exemplu: </strong>
                            Copiii își modelează adesea comportamentul după adulți, deci părinții
                            pot fi exemple pozitive prin periajul și utilizarea corectă a aței
                            dentare.
                        </p>
                        <p>
                            <strong>Frecvența și tehnicile de periaj: </strong>
                            Încearcă să faci o poveste din asta - de câte ori și cum ar trebui să se
                            spele pe dinți, inclusiv tehnica de periaj corectă și utilizarea corectă
                            a pastei de dinți.
                        </p>
                        <p>
                            <strong> Controlul dietetic: </strong>
                            Evitarea alimentelor bogate în zahăr și promovarea unei diete
                            echilibrate și sănătoase poate contribui la prevenirea cariilor dentare
                            și la menținerea unor dinți sănătoși.
                        </p>
                        <p>
                            <strong> Vizite regulate la dentist: </strong>
                            Programeaza vizite regulate la dentist, începând de la o vârstă fragedă
                            si explică-le importanța acestora pentru prevenirea problemelor dentare.
                        </p>
                        <p>
                            <strong>Consiliere pentru situații specifice: </strong>
                            Mergi la medicul stomatolog pentru situații particulare, cum ar fi
                            administrarea fluorului, utilizarea suzetei și a biberoanelor, și
                            tratarea obiceiurilor dăunătoare, precum suptul degetului sau roaderea
                            unghiilor.
                        </p>
                        <p>
                            <strong>
                                Promovarea igienei dentare ca parte a unei vieți sănătoase:{' '}
                            </strong>
                            Subliniază legătura dintre sănătatea orală și sănătatea generală,
                            evidențiind faptul că îngrijirea dinților face parte dintr-un stil de
                            viață sănătos și că promovarea acestui obicei este esențială pentru
                            bunăstarea generală a copiilor.
                        </p>
                    </>
                }
                subTitle={'Îngrijirea dinților la copii '}
                title={'Importanța igienei orale timpurii'}
                v1
            />

            <CustomSection
                image={
                    <>
                        <img src={DentalEducation9} alt="img" />
                        {/* <div className="about-text second-about">
                            <span>14</span>
                            <p>Ani de experienta</p>
                        </div> */}
                    </>
                }
                content={
                    <>
                        <p>
                            Programați o consultație la medicul stomatolog de la o vârstă cât mai
                            fragedă, avantajul fiind și acela de acomodare a copilului cu cabinetul
                        </p>
                        <p>
                            Periodic repetați experiența chiar dacă este un control de rutină, acest
                            lucru îl va ajuta pe copil să lege o relație cu medicul cu care mai
                            târziu va avea diferite interacțiuni stomatologice
                        </p>
                        <p>
                            În jurul vârstei de 6-7 ani se schimbă o serie de dinți și erup primii
                            molari permanenți, în spatele molarilor de lapte. Tot în jurul acestei
                            vârste este bine de medicul ortodont care se ocupă cu alinierea dinților
                            care nu au erupt într-o poziție corectă
                        </p>
                        <p>
                            Dimineața, după periajul dentar aruncați o privire în gura copilului și
                            dacă observați semne precum pete albe mai evidențiate decât culoarea
                            normală a dintelui, pete galbene sau chiar maro, apelați cu încredere la
                            medicul stomatolog pentru îndrumare și pentru prevenirea complicațiilor
                            dentare
                        </p>
                    </>
                }
                subTitle={'SFATURI PENTRU PĂRINȚI'}
                title={'Importanța educației dentare de mic copil'}
            />
            <div className="container">
                <strong>Sigilarea dentară</strong>
                <b>Ce înseamnă?</b>
                <p>
                    Pe suprafețele măselelor poate stagna o cantitate mare de placă bacteriană, greu
                    accesibilă periajului dentar, în special la copii. În ajutorul nostru vine
                    sigilarea dentară, care oresupune aplicarea unui material fluid care acoperă
                    șanțurile și fosetele și astfel, resturile alimentare nu mai aderă la aceste
                    suprafețe.
                </p>

                <b>Cui îi este indicată?</b>
                <p>
                    Poate fi realizată atât copiilor, cât și adulților care își doresc să prevină
                    apariția cariilor și se poate aplica la nivelul premolarilor și molarilor
                </p>
                <b>Când apelăm la sigilare?</b>
                <p>
                    Condiția esențială este ca dintele ce urmează a fi sigilat să nu aiba vreo carie
                    avansată, deci cu cât mai repede, cu atât mai bine.
                </p>
                <b>Cât ține o sigilare?</b>
                <p>
                    Poate rămâne pe suprafața dintelui și ani de zile dacă aceasta este verificată
                    periodic de medicul stomatolog și dacă igiena dentară este una corectă.
                </p>
                <strong>Fluorizarea la copii</strong>
                <b>Ce înseamnă?</b>
                <p>
                    Presupune aplicarea unui gel pe suprafețele dentare, formând o peliculă
                    protectoare care ajută la prevenirea cariilor. Prin această metodă putem să
                    stopăm demineralizarile și să orevenim apariția altora noi. Se folosește o
                    cantitate bine dozată care aderă rapid pe dinte, chiar și în condiții de izolare
                    precară, cum este cazul copiilor.
                </p>
                <b>Ce beneficii are?</b>
                <p>
                    Dacă ne-am imagina că smalțul dintelui arată ca un burete și spațiile mari și
                    goale ar fi demineralizările, atunci fluorul are capacitatea de umple aceste
                    spații și de a restabili echilibrul, astfel încât să protejeze dinții.
                </p>
                <b>De la ce vârstă se poate aplica?</b>
                <p>
                    Se poate plica la orice vârstă pe dinții de lapte sau pe dinții definitivi și
                    este o procedură sigură pentru toate vârstele. Pe parcursul unui an se recomandă
                    între 2 și 4 ședințe de fluorizare, îm funcție de caz. Manopera este rapidă și
                    nu durează mai mult de un periaj dentar.
                </p>
            </div>
        </>
    );
};

export default DentalEducation;
