import React from 'react';
import Iframe from 'react-iframe';

function Map() {
    return (
        <>
            <div className="map fix" style={{ background: '#f5f5f5' }}>
                <div className="container-flud">
                    <div className="row">
                        <div className="col-lg-12">
                            <Iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2713.2687928290666!2d27.56892057691756!3d47.15258981921819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cafb0b98561139%3A0x8f08bcb57b6f635e!2sStrada%20Pantelimon%20Halipa%2010%2C%20Ia%C8%99i%20700656%2C%20Ruman%C3%ADa!5e0!3m2!1ses!2ses!4v1719340652298!5m2!1ses!2ses"
                                width={600}
                                height={450}
                                style={{ border: '0' }}
                                loading="lazy"
                            ></Iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Map;
