import React from 'react';
import Testimonialslider from '../../components/Menhome/Testimonialslider';
import Appointment from '../../components/Menhome/Appointment';
import Map from '../../components/MenContact-Us/Map';
import HomeIMG from './../../assets/img/home/home.jpg';
import CustomSection from '../../components/CustomSection/CustomSection';
import Homevideo from '../../components/Homethreevideo/Homevideo';

const Home = () => {
    return (
        <>
            <Homevideo />
            <CustomSection
                image={
                    <>
                        <img src={HomeIMG} alt="img" />
                        {/* <div className="about-text second-about">
                            <span>14</span>
                            <p>Ani de experienta</p>
                        </div> */}
                    </>
                }
                content={
                    <>
                        <p>
                            DentEsthetics te ajută cu sfaturi de nutriție, tehnici de îngrijire
                            dentară și educație pentru un zâmbet perfect. Vizitează-ne acum pentru a
                            începe călătoria către cea mai bună versiune a zâmbetului tău!"
                        </p>
                        <ul>
                            <li>Ani de experienta - 14 ani</li>
                            <li>Zambete imbunatatite - 25.550 </li>
                            <li>Specialisti - 21</li>
                            <li>Locatii 5 locatii in judetul Iasi</li>
                        </ul>
                    </>
                }
                subTitle={'Despre noi'}
                title={''}
            />
            <br />
            <br />
            <br />
            <Testimonialslider />
            <Appointment />
            <Map></Map>
        </>
    );
};

export default Home;
