import React from 'react';
import Bredcm from '../../components/Menhome/breadcrumb';
import CustomSection from '../../components/CustomSection/CustomSection';
import MitAdever1 from './../../assets/img/mit-adever/1.jpg';
import MitAdever2 from './../../assets/img/mit-adever/2.jpg';
import MitAdever3 from './../../assets/img/mit-adever/3.jpg';
import MitAdever4 from './../../assets/img/mit-adever/4.jpg';
import MitAdever5 from './../../assets/img/mit-adever/5.jpg';
import MitAdever6 from './../../assets/img/mit-adever/6.jpg';
const MythOrTruth = () => {
    return (
        <>
            <Bredcm subtitle="Mit | Adevar" title="Acasa" subtitledown="Mit | Adevar" />
            <CustomSection
                image={
                    <>
                        <img src={MitAdever1} alt="img" />
                    </>
                }
                content={
                    <>
                        <p>
                            Acesta este unul dintre cele mai periculoase mituri. Mulți oameni nu pot
                            simți că au probleme dentare până când acestea nu devin grave. Vizitele
                            regulate la dentist sunt esențiale pentru identificarea și tratarea
                            problemelor în stadiile incipiente.
                        </p>
                    </>
                }
                subTitle={`"Dacă nu mă doare, nu am probleme dentare"`}
                title={'Mituri de demontat'}
            />
            <CustomSection
                image={
                    <>
                        <img src={MitAdever2} alt="img" />
                    </>
                }
                content={
                    <>
                        <p>
                            Folosirea unei periuțe de dinți cu peri rigizi și periajul agresiv pot
                            duce la deteriorarea smalțului și la probleme gingivale. Este important
                            să folosești o periuță de dinți cu peri moi și să periezi delicat,
                            pentru a evita leziunile.
                        </p>
                    </>
                }
                subTitle={'"Periajul dentar agresiv este mai eficient" '}
                title={'Mituri de demontat'}
                v1
            />
            <CustomSection
                image={
                    <>
                        <img src={MitAdever3} alt="img" />
                        {/* <div className="about-text second-about">
                            <span>14</span>
                            <p>Ani de experienta</p>
                        </div> */}
                    </>
                }
                content={
                    <>
                        <p>
                            Toți adulții ar trebui să facă vizite regulate la dentist pentru a
                            preveni și trata problemele dentare. Igiena orală adecvată și vizitele
                            regulate pot preveni problemele dentare costisitoare și dureroase în
                            viitor.
                        </p>
                    </>
                }
                subTitle={'"Doar copiii au nevoie de vizite la dentist"'}
                title={'Mituri de demontat'}
            />
            <CustomSection
                image={
                    <>
                        <img src={MitAdever4} alt="img" />
                        {/* <div className="about-text second-about">
                            <span>14</span>
                            <p>Ani de experienta</p>
                        </div> */}
                    </>
                }
                content={
                    <>
                        <p>
                            Albirea profesională a dinților este sigură și eficientă atunci când
                            este efectuată de către un medic dentist. Cu toate acestea, utilizarea
                            excesivă a produselor de albire la domiciliu sau procedurile
                            necontrolate pot duce la deteriorarea smalțului și la sensibilitate
                            dentară.
                        </p>
                    </>
                }
                subTitle={'"Albirea dinților este dăunătoare pentru smalț"'}
                title={'Mituri de demontat'}
                v1
            />
            {/* <CustomSection
                image={
                    <>
                        <img src={MitAdever1} alt="img" />
                    </>
                }
                content={
                    <>
                        <p>
                            Albirea profesională a dinților este sigură și eficientă atunci când
                            este efectuată de către un medic dentist. Cu toate acestea, utilizarea
                            excesivă a produselor de albire la domiciliu sau procedurile
                            necontrolate pot duce la deteriorarea smalțului și la sensibilitate
                            dentară.
                        </p>
                    </>
                }
                subTitle={'Igiena orala'}
                title={'We Care For Your Dental Health'}
            /> */}
            <CustomSection
                image={
                    <>
                        <img
                            src={MitAdever5}
                            //  style={{ transform: 'scaleX(-1) scaleY(-1)' }}
                            alt="img"
                        />
                        {/* <div className="about-text second-about">
                        <span>14</span>
                        <p>Ani de experienta</p>
                    </div> */}
                    </>
                }
                content={
                    <>
                        <p>
                            Folosirea aței dentare este esențială pentru îndepărtarea plăcii și
                            resturilor alimentare din spațiile interdentare. Este un mit că ața
                            dentară poate dăuna dinților; de fapt, ignorarea folosirii acesteia
                            poate duce la probleme gingivale și carii dentare.
                        </p>
                    </>
                }
                subTitle={'"Folosirea aței dentare poate duce la separarea dinților"'}
                title={'Mituri de demontat'}
            />
            <CustomSection
                image={
                    <>
                        <img src={MitAdever6} alt="img" />
                        {/* <div className="about-text second-about">
                    <span>14</span>
                    <p>Ani de experienta</p>
                </div> */}
                    </>
                }
                content={
                    <>
                        <p>
                            În primul rând, antibioticul trebuie obligatoriu să fie prescris de un
                            medic, nu trebuie luat după ureche. În al doilea rând, antibioticul
                            ajută la reducerea numărului de bacterii de la locul infecției, dar nu
                            va trata cauza principală, iar infecția va reveni. În cazul unei
                            infecții dentare, este absolut necesară vizita la stomatolog.
                        </p>
                    </>
                }
                subTitle={'"Dacă am o infecție, iau antibiotic și îmi trece"'}
                title={'Mituri de demontat'}
                v1
            />
        </>
    );
};

export default MythOrTruth;
