import React, { useState } from 'react';

// import Protfolioimg01 from '../../../assets/img/gallery/protfolio-img01.png';
// import Protfolioimg02 from '../../../assets/img/gallery/protfolio-img02.png';
// import Protfolioimg03 from '../../../assets/img/gallery/protfolio-img03.png';
// import Protfolioimg04 from '../../../assets/img/gallery/protfolio-img04.png';
// import Protfolioimg05 from '../../../assets/img/gallery/protfolio-img05.png';
// import Protfolioimg06 from '../../../assets/img/gallery/protfolio-img06.png';
// import Breadcrumb from '../../Menhome/breadcrumb';
import FsLightbox from 'fslightbox-react';
import { Link } from 'react-router-dom';

const CustomCompareImages = ({ images, title }) => {
    const [toggler, setToggler] = useState(false);
    const [activeImage, setActiveImage] = useState(null);

    // const images = [
    //     Protfolioimg02,
    //     Protfolioimg03,
    //     Protfolioimg01,
    //     Protfolioimg06,
    //     Protfolioimg04,
    //     Protfolioimg05
    // ];

    const handleClick = (index) => {
        setToggler(!toggler);
        setActiveImage(index);
    };

    return (
        <>
            <section id="work" className="pt-30 pb-95">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="masonry-gallery-huge gap-20">
                                <h3
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        fontWeight: '600'
                                    }}
                                >
                                    {title}
                                </h3>
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center'
                                    }}
                                >
                                    <h4
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                            fontWeight: '500'
                                        }}
                                    >
                                        Inainte
                                    </h4>
                                    <h4
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                            fontWeight: '500'
                                        }}
                                    >
                                        Dupa
                                    </h4>
                                </div>
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center',

                                        gap: '20px'
                                    }}
                                >
                                    {images.map((image, index) => (
                                        <div key={index} style={{ width: '100%' }}>
                                            <Link
                                                className="popup-image lightbox-image"
                                                data-fancybox="gallery"
                                                to="#"
                                                onClick={() => handleClick(index + 1)}
                                            >
                                                <figure className="gallery-image">
                                                    <img
                                                        src={image}
                                                        alt={`img-${index}`}
                                                        className="img"
                                                    />
                                                </figure>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FsLightbox toggler={toggler} sources={images} slide={activeImage} />
        </>
    );
};
export default CustomCompareImages;
