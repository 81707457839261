import axios from 'axios';
import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
const CustomContactForm = () => {
    const [initialStateForm, setInitialStateForm] = useState({
        client_name: '',
        email: '',
        phone: '',
        message: ''
    });
    const ContactFormSchema = Yup.object().shape({
        client_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        phone: Yup.string().required('Required'),
        message: Yup.string().required('Required')
    });
    const handleSubmit = ({ client_name, email, phone, message }) => {
        const serviceID = 'service_02edq15';
        const templateID = 'template_jvax3ch';
        const publicKey = 'CScFA2JuxxcfN0GRT';

        const data = {
            service_id: serviceID,
            template_id: templateID,
            user_id: publicKey,
            template_params: {
                from_name: client_name,
                from_email: email,
                from_phone: phone,
                to_name: 'Esthetics',
                message: message
            }
        };

        try {
            axios
                .post('https://api.emailjs.com/api/v1.0/email/send', data)
                .then(() => {
                    //alert('success');
                    toast.success('Email trimis corect');
                    setInitialStateForm({
                        client_name: '',
                        email: '',
                        phone: '',
                        message: ''
                    });
                })
                .catch(() => {
                    //alert('error');
                    toast.error('Mesajul nu a fost trimis');
                });
        } catch (error) {
            //alert('Filaed', error);
            //console.log('error', error);
            toast.error('Failed');
        }
    };

    return (
        <>
            <div className="col-lg-8 order-1">
                <div className="contact-bg02">
                    <div className="section-title center-align">
                        <h2>Intreaba-ne!</h2>
                    </div>
                    <Formik
                        initialValues={initialStateForm}
                        validationSchema={ContactFormSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form className="contact-form mt-30">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="contact-field p-relative c-name mb-20">
                                            <Field
                                                type="text"
                                                id="client_name"
                                                name="client_name"
                                                placeholder="Nume*"
                                                className={
                                                    errors.client_name && touched.client_name
                                                        ? 'is-invalid'
                                                        : ''
                                                }
                                            />
                                            <ErrorMessage
                                                name="client_name"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="contact-field p-relative c-subject mb-20">
                                            <Field
                                                type="email"
                                                id="email"
                                                name="email"
                                                placeholder="Email*"
                                                className={
                                                    errors.email && touched.email
                                                        ? 'is-invalid'
                                                        : ''
                                                }
                                            />
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="contact-field p-relative c-subject mb-20">
                                            <Field
                                                type="text"
                                                id="phone"
                                                name="phone"
                                                placeholder="Telefon"
                                                className={
                                                    errors.phone && touched.phone
                                                        ? 'is-invalid'
                                                        : ''
                                                }
                                            />
                                            <ErrorMessage
                                                name="phone"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="contact-field p-relative c-message mb-30">
                                            <Field
                                                as="textarea"
                                                id="message"
                                                name="message"
                                                cols={30}
                                                rows={10}
                                                placeholder="Mesaj"
                                                className={
                                                    errors.message && touched.message
                                                        ? 'is-invalid'
                                                        : ''
                                                }
                                            />
                                            <ErrorMessage
                                                name="message"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ color: 'tomato', fontWeight: 'bold' }}>
                                            <ErrorMessage name="errorsubmit" />
                                        </div>
                                        <div className="slider-btn">
                                            <button className="btn ss-btn" type="submit">
                                                <span>Trimite mesaj</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default CustomContactForm;
