import React from 'react';
import Featuresservices from '../../assets/img/features/services-img-details2.png';
import FeaturesservicesTwo from '../../assets/img/features/about_img.png';
import { Link } from 'react-router-dom';

function Ourservices() {
    const data = [
        {
            id: 1,
            name: 'Igiena orală',
            text: 'Cum realizam igiena orală in mod corect',
            img: 'assets/img/icon/fe-icon01.png'
        },
        {
            id: 2,
            name: 'Albirea dinților',
            text: 'Când și cum să alegi această procedură',
            img: 'assets/img/icon/fe-icon04.png'
        },
        {
            id: 3,
            name: 'Pasta de dinți',
            text: 'Cum alegem pasta de dinți',
            img: 'assets/img/icon/fe-icon07.png'
        },
        {
            id: 4,
            name: 'Sarcina si sănătatea orală',
            text: 'Îngrijirea stomatologică în timpul sarcinii',
            img: 'assets/img/icon/fe-icon07.png'
        }
    ];
    const dataone = [
        {
            id: 5,
            name: 'Teama de dentist ',
            text: 'Cum depașim teama de dentist',
            img: 'assets/img/icon/fe-icon06.png'
        },
        {
            id: 6,
            name: 'Sensibilitatea dentară',
            text: 'Cauzele sensibilității dentare',
            img: 'assets/img/icon/fe-icon05.png'
        },
        {
            id: 7,
            name: 'Riscurile procedurilor stomatologice',
            text: 'Care sunt riscurile unui tratamen dentar',
            img: 'assets/img/icon/fe-icon08.png'
        },
        {
            id: 8,
            name: 'Îngrijirea dinților la copii ',
            text: 'Importanța igienei orale timpurii',
            img: 'assets/img/icon/fe-icon08.png'
        }
    ];
    return (
        <>
            <section id="service-details2" className="service-details-two pt-120 pb-105 p-relative">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="section-title center-align mb-50 text-center">
                                {/* <h5>Our Services</h5> */}
                                <h2>Educatie dentara</h2>
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="about-content s-about-content">
                                <ul className="sr-tw-ul text-right">
                                    {data.map((item) => (
                                        <li key={item.id}>
                                            <div className="text">
                                                <h4>{item.name}</h4>
                                                <p>{item.text}</p>
                                            </div>
                                            <div className="icon-right">
                                                <img src={item.img} alt="icon01" />
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-4 col-md-12 col-sm-12 text-center d-none d-lg-block">
                            <div className="sd-img">
                                <img src={Featuresservices} alt="img" />
                            </div>
                        </div> */}
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="about-content s-about-content">
                                <ul className="sr-tw-ul">
                                    {data.map((item) => (
                                        <li key={item.id}>
                                            <div className="icon">
                                                <img src={item.img} alt="icon02" />
                                            </div>
                                            <div className="text pt-10">
                                                <h4>{item.name}</h4>
                                                <p>{item.text}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="about-content s-about-content">
                                <ul className="sr-tw-ul">
                                    {dataone.map((item) => (
                                        <li key={item.id}>
                                            <div className="icon">
                                                <img src={item.img} alt="icon02" />
                                            </div>
                                            <div className="text pt-10">
                                                <h4>{item.name}</h4>
                                                <p>{item.text}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <CustomSection
                image={
                    <>
                        <img src={FeaturesservicesTwo} alt="img" />
                        <div className="about-text second-about">
                            <span>14</span>
                            <p>Ani de experienta</p>
                        </div>
                    </>
                }
                content={
                    <>
                        <p>
                            Aliquam ac sem et diam iaculis efficitur. Morbi in enim odio. Nullam
                            quis volutpat est, sed dapibus sapien. Cras condimentum eu velit id
                            tempor. Curabitur purus sapien, cursus sed nisl tristique, commodo
                            vehicula arcu.
                        </p>
                        <ul>
                            <li>Ani de experienta - 14 ani</li>
                            <li>Zambete imbunatatite - 25.550 </li>
                            <li>Specialisti - 21</li>
                            <li>Locatii 5 locatii in judetul Iasi</li>
                        </ul>
                    </>
                }
                subTitle={'About Us'}
                title={'We Care For Your Dental Health'}
            /> */}
            {/* 
            <section className="about-area about-p pb-120 p-relative fix">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div
                                className="s-about-img p-relative  wow fadeInLeft animated"
                                data-animation="fadeInLeft"
                                data-delay=".4s"
                            >
                                <img src={FeaturesservicesTwo} alt="img" />
                                <div className="about-text second-about">
                                    <span>50</span>
                                    <p>Years of Experience</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div
                                className="about-content s-about-content  wow fadeInRight  animated"
                                data-animation="fadeInRight"
                                data-delay=".4s"
                            >
                                <div className="about-title second-title pb-25">
                                    <h5>About Us</h5>
                                    <h2>We Care For Your Dental Health</h2>
                                </div>
                                <p>
                                    Aliquam ac sem et diam iaculis efficitur. Morbi in enim odio.
                                    Nullam quis volutpat est, sed dapibus sapien. Cras condimentum
                                    eu velit id tempor. Curabitur purus sapien, cursus sed nisl
                                    tristique, commodo vehicula arcu.
                                </p>
                                <p>
                                    Aliquam erat volutpat. Aliquam enim massa, sagittis blandit ex
                                    mattis, ultricies posuere sapien. Morbi a dignissim enim. Fusce
                                    elementum, augue in elementum porta, sapien nunc volutpat ex, a
                                    accumsan nunc lectus eu lectus.
                                </p>
                                <div className="slider-btn mt-20">
                                    <Link to="about.html" className="btn ss-btn smoth-scroll">
                                        Read More
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
}

export default Ourservices;
