import React from 'react';
import Bredcm from '../../components/Menhome/breadcrumb';
import IMG1 from './../../assets/img/medical-procedures/tratarae_carie.jpg';
import IMG2 from './../../assets/img/medical-procedures/implantul_dentar.jpg';
import IMG3 from './../../assets/img/medical-procedures/tratamentul_endodontic.jpg';
import IMG4 from './../../assets/img/medical-procedures/ocluzia_dentara.jpg';
import IMG5 from './../../assets/img/medical-procedures/boala_parodontala.jpeg';
import CustomSection from '../../components/CustomSection/CustomSection';

const MedicalProcedures = () => {
    return (
        <>
            <Bredcm subtitle="Proceduri medicale" title="Acasa" subtitledown="Proceduri medicale" />
            <CustomSection
                image={
                    <>
                        <img src={IMG1} alt="img" />
                    </>
                }
                content={
                    <>
                        <p>
                            Cavitatea bucală este gazdă a câteva milioane de bacterii în mod
                            natural. Atunci când sunt prezente fragmente alimentare, în special
                            carbohidrați sau glucide, bacteriile se hrănesc cu aceste resturi,
                            eliberând apoi produși de metabolism care au un PH acid. Acest acid va
                            eroda smalțul generând microfisuri care se transformă  în timp în
                            cavități întinse.
                        </p>
                        <p>
                            Leziunile carioase pe dinții frontali apar de cele mai multe ori pe
                            părțile laterale, fiind greu de identificat în stadiile incipiente. În
                            evoluție, ele produc o distrucție a smalțului sub forma unui cotor de
                            măr.
                        </p>
                        <p>
                            La nivelul dinților laterali (măselelor), caria se produce în
                            adânciturile suprafețelor orizontale, deoarece acela este un loc
                            retentiv pentru resturi alimentare și, implicit, pentru bacterii.
                        </p>
                    </>
                }
                subTitle={'TRATAREA CARIEI'}
                title={'Cum apar cariile?'}
            />
            <div className="container">
                <h3>Cum tratăm cariile?</h3>

                <p>
                    Țesutul dentar modificat de culoare (cu nuanță neagră, maro) sau de consistentă
                    ( moale, cretos) trebuie neapărat îndepărtat pentru că este colonizat de
                    bacterii. Îndepărtarea se poate face cu instrumentar rotativ sau manual.
                </p>
                <p>
                    Se obține astfel o cavitate cu pereți de dinte integru la nivelul cărora se va
                    aplica un protocol adeziv cu rolul de a menține materialul restaurator.
                </p>
                <p>
                    În cazul în care se obține o cavitate foarte întinsă sau adâncă, apropiată de
                    nervul dentar, se poate aplica un material intermediar cu rolul de a
                    remineraliza țesutul dentar și de a proteja nervul.
                </p>
                <p>
                    Materialul restaurator final, numit compozit, poate avea proprietăți diferite în
                    funcție de zona în care este utilizat, dinți frontali sau laterali.
                </p>
                <p>
                    Alegerea potrivită a culorii compozitului sau mixarea mai multor nuanțe va face
                    ca obturația (plombă) să arate cât mai natural, cât mai aproape de dintele
                    natural, astfel încât să pară că nu s-a efectuat nicio intervenție
                    stomatologică, iar zâmbetul să fie perfect.
                </p>
            </div>
            <br />
            <br />
            <br />
            <CustomSection
                image={
                    <>
                        <img src={IMG2} alt="img" />
                    </>
                }
                content={
                    <>
                        <ul>
                            <li>
                                - Dinții vecini vor migra, se vor înclina către locul lăsat liber,
                                ajungând într-o poziție oblică, nefavorabilă pentru masticație sau
                                pentru autocurățare
                            </li>
                            <li>
                                - Dintele antagonist, cel cu care a avut contact dintele lipsă, va
                                “plonja” în spațiul gol, adică va ieși din os, dezvoltând o
                                afecțiune parodontală, sensibilitate dureroasă și chiar mobilitate
                            </li>
                            <li>
                                - Osul și gingia se vor resorbi, ducând la apariția de leziuni ale
                                mucoasei orale și modificări ale conturului arcadei dentare
                            </li>
                            <li>
                                - Funcția masticatorie va fi afectată, alimentele nu vor mai putea
                                fi triturate suficient de bine, ceea ce poate duce la afecțiuni
                                gastrice
                            </li>
                            <li>
                                - Absența contactelor dentare sau existența unor contacte
                                dezechilibrate va duce la o disfuncție a articulației
                                temporo-mandibulare care poate genera patologii auditive precum
                                tinitus ( țiuit în ureche), sindrom vestibular, scăderea acuității
                                vizuale, durere de cap, dureri ale coloanei cervicale, precum și
                                modificări de postură
                            </li>
                        </ul>
                    </>
                }
                subTitle={'IMPLANTUL DENTAR'}
                title={'Consecințele pierderii dinților'}
                v1
            />
            <div className="container">
                <ul>
                    <li>
                        - Aspectul poate fi modificat în primul rând prin absența dinților, iar în
                        al doilea rând, prin modificarea musculaturii și conturului feței
                    </li>
                    <li>
                        • Tratamentul cu implanturi dentare este unul simplu și sigur de care poate
                        beneficia orice pacient, indiferent de vârstă.{' '}
                    </li>
                    <li>
                        • Implantul este biocompatibil, confecționat din titan medical. Nu există
                        contraindicații pentru efectuarea unei investigații cu rezonanță magnetică.
                    </li>
                    <li>
                        • Intervenția este rapidă și nedureroasă. Vindecarea durează aproximativ 14
                        zile, timp în care este necesar să fie respectate câteva indicații
                        suplimentare de igienă orală și alimentație.{' '}
                    </li>
                    <li>
                        • Integrarea implantului în osul maxilar are loc într-un interval de 4-6
                        luni, în funcție de calitatea osului nativ. După această perioadă va fi
                        aplicată lucrarea protetică pe suport implantar, aceasta fiind realizată din
                        materiale superioare, precum titan sau zirconiu.
                    </li>
                    <li>
                        • În cazul unei edentații totale ( absența tuturor dinților ) se poate opta
                        pentru tratament Fast & Fix (Bredent®), ceea ce presupune inserarea a 6 sau
                        8 implanturi și aplicarea unei lucrări protetice fixe provizorii în 24 ore.
                        Această intervenție se poate efectua sub sedare conștientă, datorită
                        colaborării cu medic specialist anestezie și terapie intensivă.
                    </li>
                </ul>
            </div>
            <br />
            <br />
            <br />
            <CustomSection
                image={
                    <>
                        <img src={IMG3} alt="img" />
                    </>
                }
                content={
                    <>
                        <p>
                            Tratamentul endodontic sau tratamentul de canal este o succesiune de
                            proceduri terapeutice care are ca rezultat final eliminarea infecției de
                            la nivelul pulpei dentare și protejarea zonelor decontaminate de alte
                            agresiuni microbiene.Tratamentul endodontic este indicat în cazul
                            infecțiilor pulpare sau când se consideră că o viitoare infecție a
                            pulpei dentare este inevitabilă.În asemenea situații, tratamentul de
                            canal este necesar pentru a salva dintele și a preveni complicațiile ce
                            ar putea apărea în țesuturile periapicale
                        </p>
                        <p>
                            Tratamentul endodontic se efectuează sub anestezie locală. Medicul
                            dentist poate izola dintele tratat cu ajutorul unui dispozitiv numit
                            digă. Aceasta va menține câmpul operator uscat și curat pe tot parcursul
                            procedurii și va scădea riscul înghițirii accidentale de ace endodontice
                            sau soluții de irigare.
                        </p>
                    </>
                }
                subTitle={'TRATAMENTUL ENDODONTIC'}
                title={'Eliminarea infecției la nivelul pulpei dentare'}
            />
            <div className="container">
                <p>
                    Fără ajutorul microscopului, tratamentul endodontic se desfășoară orbește,
                    bazându-ne doar pe sensibilitatea noastră tactilă, iar singura cale de a vedea
                    în interiorul canalului este prin intermediul radiografiei retroalveolare sau
                    tomografiei computerizate. De asemenea, problemele întâmpinate, cum ar fi
                    calcificările, pragurile, acele rupte, perforațiile pot fi doar simțite și
                    rareori rezolvate fără vizualizarea lor directă.Cu ajutorul microscopului în
                    schimb, orice problemă aflată în porțiunea dreaptă accesibilă a canalului
                    radicular, poate fi vizualizată, adresată și, în final, putem oferi un
                    prognostic mult mai bun.
                </p>

                <p>
                    Microscopul endodontic ne oferă o vizibilitate mult mai bună asupra câmpului
                    operator. Asta înseamnă că imaginea oferită de microscop este foarte mărită și
                    foarte clară. Culorile sunt bine evidențiate. Un dinte nu este doar alb.
                    Prezintă mai multe nuanțe: de gri, alb, alb opac sau gălbui. Fiecare culoare
                    poate fi asociată cu un simptom respectiv cu o cauză. Cel mai frecvent, la
                    microscop, retratăm dinții, adică efectuăm retratamente endodontice. Asta
                    înseamnă că dintele a fost tratat în prealabil endodontic iar tratamentul a
                    eșuat. Foarte des este asociat cu o durere, cu o infecție și pacientul se
                    prezintă la cabinet pentru tratarea acelui simptom. De fapt, noi refacem
                    tratamentul endodontic.
                </p>
                <p>
                    Cu ajutorul tehnologiilor moderne, tratamentele de canal ar trebui să nu mai
                    sperie pacienții, ci să fie realizate într-un timp relativ scurt, fără durere și
                    fără complicații, pentru că este cel mai bun tratament conservator cu scopul de
                    a salva un dinte afectat.
                </p>
            </div>
            <br />
            <br />
            <br />
            <CustomSection
                image={
                    <>
                        <img src={IMG4} alt="img" />
                    </>
                }
                content={
                    <>
                        <p>
                            Ocluzia dentară reprezintă modul în care dinții superiori îi ating pe
                            cei inferiori, atât în timpul masticatiei, cât și atunci când ținem gura
                            închisă, rezultând contactele dentare.
                        </p>

                        <p>
                            Aceste contacte trebuie să aibă o distribuție și o forță echilibrate.
                            Atunci când intensitatea acestor contacte este prea ridicată, apar o
                            serie de complicații precum fisuri sau chiar fracturi dentare,
                            mobilitatea dinilor, retracții și sângerări gingivale, dureri musculare
                            sau ale articulației temporo-mandibulare  și chiar dureri de cap, de gât
                            sau dureri ale coloanei vertebrale.
                        </p>

                        <p>
                            Ocluzia dentară este influențată de poziția, forma și structura dinilor,
                            de nervi și mușchi, de articulația temporo-mandibulară și, nu în ultimul
                            rând, de postură.
                        </p>
                    </>
                }
                subTitle={'OCLUZIA DENTARĂ'}
                title={'Cum poți afla dacă ai o ocluzie corectă?'}
                v1
            />
            <div className="container">
                <p>
                    În cadrul unei vizite de evaluare a ocluziei, medicul stomatolog va efectua o
                    verificare o contactelor dentare, direct în cavitatea orală, va realiza câteva
                    fotografii și un model de studiu din ghips, adică o copie a dinților tăi.
                </p>
                <p>
                    Pentru corectarea ocluziei, în funcție de gradul de severitate, medicul poate
                    propune câteva metode simple, precum realizarea unei gutiere care se poartă pe
                    timp de noapte sau înlocuirea unor plombe vechi.
                </p>
                <p>
                    În cazul unor malocluzii severe, poate fi necesar să se realizeze lucrări
                    protetice ample, să se instaureze un tratament ortodontic de repoziționare a
                    dinților și chiar efectuarea unor ședințe de kinetoterapie.
                </p>
                <p>
                    În aceste cazuri, colaborarea interdisciplinară este esențială, între medicul
                    stomatolog protetician, medicul ortodont și kinetoterapeut, astfel încât ocluzia
                    dentară a pacientului, articulația temporo-mandibulară și coloana vertebrală să
                    se afle într-un echilibru care să ofere confort pacientului.
                </p>
                <p>
                    Lucrarea protetică este o piesă realizată în laboratorul de tehnică dentară care
                    înlocuiește un dinte lipsă sau acoperă un dinte care a fost afectat de carie.
                </p>
                <p>
                    Lucrările protetice fixe având ca suport dinții naturali îți vor oferi
                    posibilitatea de a realiza o masticație normală, fără durere, vor reda
                    integritatea arcadei dentare și a zâmbetului armonios, contribuind la creșterea
                    stimei în propria persoană.
                </p>
                <p>
                    Pentru un tratament protetic este necesară întâi o întâlnire cu medicul
                    stomatolog protetician în cadrul căreia va avea loc o evaluare a statusului
                    dentar și a ocluziei dentare, se vor realiza câteva fotografii preliminare și un
                    model de studiu din ghips.
                </p>
                <p>
                    În următoarea etapă are loc pregătirea dinilor prin șlefuire și amprentare. În
                    acest sens, o tehnică modernă o reprezintă amprenta optică ce utilizează un
                    scanner intraoral, asemenea unei camere video care înregistrează imaginea
                    dinților și a țesuturilor din jur. Comparativ cu amprenta clasică ce presupunea
                    modelarea unui material asemănător plastilinei, această metodă are ca avantaje o
                    precizie crescută a viitoarei piese protetice,  înlăturarea senzației de greață,
                    confortul ridicat în timpul manoperei, absența durerii și timpul redus.
                </p>
                <p>
                    Materialul cel mai utilizat în acest moment în realizarea lucrărilor protetice
                    este zirconiul.
                </p>
                <p>
                    Acesta poate fi utilizat ca atare, în zona laterală, la nivelul măselelor, acolo
                    unde forțele de masticație au o intensitate crescută și este necesară utilizarea
                    unui material cu un coeficient mărit de rezistență.
                </p>
                <p>
                    Pentru zona frontală, cerințele estetice sunt ridicate, așa încât, în această
                    zonăse indică folosirea ceramicii pe support de zirconiu sau chiar a ceramicii
                    integrale, aceastadin urmă având proprietăți estetice deosebite, precum
                    translucenta, transparență, oferind un aspect aproape identic cu cel al dinților
                    naturali.
                </p>
            </div>
            <br />
            <br />
            <br />
            <CustomSection
                image={
                    <>
                        <img src={IMG5} alt="img" />
                    </>
                }
                content={
                    <>
                        <p>
                            Boala parodontală reprezintă afectarea țesuturilor de susținere a
                            dinților și anume țesutul moale, gingia, și țesutul dur, osul.
                        </p>
                        <p>
                            Poate avea mai multe stadii, de la o ușoară inflamare a gingiilor, până
                            la pierderea tuturor dinților, în stadiul cel mai avansat.
                        </p>

                        <p>
                            <strong>
                                Care sunt semnele că am putea suferi de boala parodontală?
                            </strong>
                            <br></br>
                            Această afecțiune se manifestă prin sângerare gingivală la periaj,
                            respirație urât mirositoare și gust neplăcut, gingii roșii și umflate,
                            mobilitate dentară sau durere în timpul masticației.
                        </p>
                        <p>
                            <strong>Care sunt cauzele apariției bolii parodontale?</strong>
                            <br></br>
                            Această afecțiune se manifestă prin sângerare gingivală la periaj,
                            respirație urât mirositoare și gust neplăcut, gingii roșii și umflate,
                            mobilitate dentară sau durere în timpul masticației.
                        </p>
                    </>
                }
                subTitle={'BOALA PARODONTALĂ'}
                title={'Care sunt semnele parodontozei?'}
            />
            <div className="container">
                <p>
                    <strong>Care sunt semnele că am putea suferi de boala parodontală?</strong>
                    <br></br>
                    Printre factorii cei mai importanți se numără igienă orală deficitară, prezența
                    tartrului și fumatul. De asemenea, o tehnică de periaj incorectă, utilizarea
                    excesivă a scobitorilor sau prezența unor lucrări protetice vechi mai pot avea
                    un rol în apariția afecțiunilor parodontale. La felcum anumite condiții
                    sistemice precum diabetul, bolile hepatice, alcoolismul său schimbările
                    hormonale pot produce modificări la nivelul țesuturilor de susținere a dinților
                    și necesită măsuri suplimentare.
                </p>
                <p>
                    <strong>Exista tratament pentru boala parodontala?</strong>
                    <br></br>
                    Tratamentul bolii parodontale urmărește 2 direcții: reducerea inflamației la
                    nivelul gingiilor și obținerea unui atașament stabil al dinților în țesutul dur,
                    anume osul alveolar.
                </p>
                <p>
                    În primele stadii ale bolii se efectuează detartrajul supra și subgingival care
                    presupune îndepărtarea depozitelor de tartru. În acest sens, o inovație o
                    reprezintă aparatul VectorParo, un patent german revoluționar, care, spre
                    deosebire de piesele de detartraj clasice care folosesc o mișcare eliptică,
                    acesta utilizează o mișcarea liniară a vibrației ultrasonice, eliminând astfel
                    durerea din timpul tratamentului.
                </p>
                <p>
                    În fazele mai avansate ale bolii se pot realiza o serie de proceduri clasice
                    precum aplicarea de soluții antiseptice sau chiar tratamente antibiotice,
                    surfasajul suprafețelor radiculare, imobilizarea dinților mobili sau metode
                    moderne care și-au dovedit eficiență precum laserul terapeutic sau tehnică
                    PRF.Laserul terapeutic tip dioda are acțiune bactericidă și antiinflamatorie, nu
                    încălzește țesuturile, ci are efect de calmare a durerilor. Rezultatele sunt
                    vizibile încă de la prima ședință, dar este necesară respectarea unui protocol
                    de aproximativ 6 ședințe pentru un tratament complet.
                </p>
                <p>
                    Tehnica PRF presupune recoltarea unei cantitați mici de sânge venos și, prin
                    centrifugare, are loc separarea plasmei bogată în trombocite, proteine, fibrină
                    și factori de creștere proprii ai pacientului. Acest lichid se injectează în
                    zonele afectate, având rol în diminuarea durerii, reducerea inflamației și
                    stimularea vindecării naturale a țesuturilor. Este un tratament lipsit de orice
                    riscuri sau efecte secundare întrucât se utilizează un produs autolog, propriul
                    sânge al pacientului. Toate aceste metode trebuie completate de tratarea
                    celorlalte leziuni carioase sau procese infecțioase ale cavității bucale, și,
                    bineînțeles, de o igienă foarte riguroasă pe care pacientul trebuie să o
                    realizeze acasă în urma indicațiilor medicului stomatolog.
                </p>
            </div>
            <br />
            <br />
            <br />
        </>
    );
};

export default MedicalProcedures;
