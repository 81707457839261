import React from 'react';
import FeaturesservicesTwo from '../../assets/img/features/about_img.png';

const CustomSection = ({ title, subTitle, content, image, v1 }) => {
    return (
        <section className="about-area about-p pb-20 p-relative fix mt-20">
            <div className="container">
                <div
                    className={`row justify-content-center align-items-center ${
                        v1 ? 'flex-row-reverse' : ''
                    }`}
                >
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div
                            className="s-about-img p-relative  wow fadeInLeft animated"
                            data-animation="fadeInLeft"
                            data-delay=".4s"
                        >
                            {image}
                            {/* <img src={FeaturesservicesTwo} alt="img" />
                            <div className="about-text second-about">
                                <span>14</span>
                                <p>Ani de experienta</p>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div
                            className="about-content s-about-content  wow fadeInRight  animated"
                            data-animation="fadeInRight"
                            data-delay=".4s"
                        >
                            <div className="about-title second-title pb-25">
                                <h5>{title}</h5>
                                <h2>{subTitle}</h2>
                            </div>
                            {content}

                            {/* <div className="about-title second-title pb-25">
                                <h5>About Us {title}</h5>
                                <h2>We Care For Your Dental Health{subTitle}</h2>
                            </div> */}
                            {/* <p>
                                Aliquam ac sem et diam iaculis efficitur. Morbi in enim odio. Nullam
                                quis volutpat est, sed dapibus sapien. Cras condimentum eu velit id
                                tempor. Curabitur purus sapien, cursus sed nisl tristique, commodo
                                vehicula arcu.
                            </p>
                            <ul>
                                <li>Ani de experienta - 14 ani</li>
                                <li>Zambete imbunatatite - 25.550 </li>
                                <li>Specialisti - 21</li>
                                <li>Locatii 5 locatii in judetul Iasi</li>
                            </ul> */}

                            {/* <div className="slider-btn mt-20">
                        <Link to="about.html" className="btn ss-btn smoth-scroll">
                            Read More
                        </Link>
                    </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CustomSection;
